import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query GetAllRRImages {
      allFile(sort: {order: ASC, fields: absolutePath}, filter: { sourceInstanceName: { eq: "rr" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(quality: 80, transformOptions: {fit: CONTAIN})
            }
          }
        }
      }
    }
  
  `);
  const images = data.allFile.edges.map((e: any) => e.node);
  return (
    <div>
      {/* <Head>
        <title>my little photo exhibition</title>
        <meta name="description" content="little photo exhibition by MicroBenz" />
      </Head> */}

      <main className="container px-4 py-16 mx-auto md:px-0">
        <div className="pb-8">
          <h1 className="text-center">
            you said to me that i should open my photo exhibition
          </h1>
          <p className="text-center">now i do that (in the nerdy way) as you mentioned before.</p>
          <p className="text-center">thank you that brought me to the world of film camera :)</p>
          <p className="text-center">might be too soon but i liked being a film photographer already</p>
          <p className="text-center">🤍</p>
        </div>
        <div className="grid items-center grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-4">
          {images.map((img: any, idx: number) => (
            <div key={img.relativePath}>
              <GatsbyImage
                alt="Image Alt"
                image={getImage(img)}
              />
            </div>
          ))}
        </div>
        <div className="pt-8">
          <p className="text-center">if there is a chance that you would be on my film again, i will not hesitate to do that.</p>
        </div>
      </main>
    </div>
  );
}

export default IndexPage
